import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../utils/request'
import {
  TABLEAU_REPORT_LIST_URL, TABLEAU_REPORT_URL, TABLEAU_TOKEN_URL, TRACK_REPORT_URL,
} from './api-constraints'
import { checkSessionExpired } from './user'

export const getReportInfo = createAsyncThunk(
  'getReportInfo',
  async (data, { dispatch, rejectWithValue }) => {
    let report = null
    let ticket = null
    try {
      report = await get({
        url: `${TABLEAU_REPORT_URL}/${data.supplierId}/${data.reportId}`,
      })
      ticket = await get({
        url: TABLEAU_TOKEN_URL,
      })
    } catch (err) {
      dispatch(checkSessionExpired(err.response))
      return rejectWithValue({ error: err.response })
    }
    return { report, ticket }
  },
)

export const getReportList = createAsyncThunk(
  'getReportList',
  async (supplierId, { dispatch, rejectWithValue }) => {
    let reportList = null
    try {
      reportList = await get({
        url: `${TABLEAU_REPORT_LIST_URL}/${supplierId}`,
      })
    } catch (err) {
      dispatch(checkSessionExpired(err.response))
      return rejectWithValue({ error: err.response })
    }
    return reportList
  },
)

export const trackViewReport = createAsyncThunk(
  'trackReport',
  async (data, { dispatch, rejectWithValue }) => {
    let report = null
    try {
      report = await post({
        url: TRACK_REPORT_URL,
        body: data,
      })
    } catch (err) {
      dispatch(checkSessionExpired(err.response))
      return rejectWithValue({ error: err.response })
    }
    return report
  },
)
