import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  del, get, patch, post,
} from '../../utils/request'
import { storage } from '../../utils/token'
import {
  LOGIN_URL,
  OTP_URL,
  LOGOUT_URL,
  USER_DATA_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  SET_PASSWORD_URL,
  FAVORITE_URL,
  CHANGE_PASSWORD_LOGIN_URL,
  RESEND_OTP_URL,
} from './api-constraints'
import { setUnHandleError } from '../reducers/appReducer'

const UNAUTHORIZEDCODE = 401

export const checkSessionExpired = createAsyncThunk('checkSessionExpired', async (error) => error?.status === UNAUTHORIZEDCODE)

export const login = createAsyncThunk('login', async (userData, { rejectWithValue }) => {
  const data = {
    email: userData.username,
    password: userData.password,
  }
  let output
  try {
    output = await post({
      url: LOGIN_URL,
      body: data,
      headers: {
        platform: 'dashboard',
      },
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  if (output.expired === false) storage.set('jwtToken', output.token)
  return output
})

export const logOut = createAsyncThunk('logout', async () => {
  post({
    url: LOGOUT_URL,
  })
  storage.remove('jwtToken')
})

export const forgotPassword = createAsyncThunk('forgotPassword', async (emailData, thunkAPI) => {
  const data = {
    email: emailData,
  }
  let output
  try {
    output = await post({
      url: FORGOT_PASSWORD_URL,
      body: data,
    })
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data.error.message)
  }
  return output
})

export const verfyOTP = createAsyncThunk('verfyOTP', async (otpToken, { getState, rejectWithValue }) => {
  let output
  const data = {
    verifyOtpToken: getState().User.preToken,
    token: otpToken,
  }
  try {
    output = await post({
      url: OTP_URL,
      body: data,
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  storage.set('jwtToken', output.token)
  return output
})

export const resendOTP = createAsyncThunk('resendOTP', async (payload, { getState, rejectWithValue }) => {
  let output
  const data = {
    verifyOtpToken: getState().User.preToken,
  }
  try {
    output = await post({
      url: RESEND_OTP_URL,
      body: data,
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  return output
})

export const loadUserData = createAsyncThunk('loadUserData', async (payload, { dispatch, rejectWithValue }) => {
  let output
  try {
    output = await get({
      url: USER_DATA_URL,
    })
  } catch (err) {
    dispatch(checkSessionExpired(err.response))
    return rejectWithValue(err.response.data.error)
  }
  return output
})

export const resetPassword = createAsyncThunk('resetPassword', async (userData, { rejectWithValue }) => {
  let output
  const { token } = userData
  const data = {
    password: userData.password,
  }
  try {
    output = await patch({
      url: `${RESET_PASSWORD_URL}/${token}`,
      body: data,
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  return output
})

export const setPassword = createAsyncThunk('setPassword', async (userData, { rejectWithValue }) => {
  let output
  const data = {
    token: userData.token,
    password: userData.password,
  }
  try {
    output = await post({
      url: SET_PASSWORD_URL,
      body: data,
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  return output
})

export const getFavoriteList = createAsyncThunk('getFavoriteList', async (payload, { dispatch, rejectWithValue }) => {
  let favList = null
  try {
    favList = await get({
      url: FAVORITE_URL,
    })
  } catch (err) {
    dispatch(checkSessionExpired(err.response))
    return rejectWithValue({ error: err.response })
  }
  return favList
})

export const addFavorite = createAsyncThunk('addFavorite', async (reportId, { dispatch, rejectWithValue }) => {
  const output = null
  try {
    await patch({
      url: `${FAVORITE_URL}/${reportId}`,
    }).then(() => {
      dispatch(getFavoriteList())
    })
  } catch (err) {
    err.response?.status === UNAUTHORIZEDCODE ? dispatch(checkSessionExpired(err.response))
      : dispatch(setUnHandleError({ contain: true, statusCode: err.response?.status }))
    return rejectWithValue({ error: err.response })
  }
  return output
})

export const removeFavorite = createAsyncThunk('removeFavorite', async (reportId, { dispatch, rejectWithValue }) => {
  const output = null
  try {
    await del({
      url: `${FAVORITE_URL}/${reportId}`,
    }).then(() => {
      toast.success('Report has been removed from favorites')
      dispatch(getFavoriteList())
    })
  } catch (err) {
    err.response?.status === UNAUTHORIZEDCODE ? dispatch(checkSessionExpired(err.response))
      : dispatch(setUnHandleError({ contain: true, statusCode: err.response?.status }))
    return rejectWithValue({ error: err.response })
  }
  return output
})

export const changePassword = createAsyncThunk('CHANGE_PASSWORD_LOGIN_URL', async (userData, { rejectWithValue }) => {
  let output
  const data = {
    password: userData.password,
    newPassword: userData.newPassword,
  }
  try {
    output = await patch({
      url: `${CHANGE_PASSWORD_LOGIN_URL}`,
      body: data,
    })
  } catch (err) {
    return rejectWithValue({ error: err.response.status })
  }
  return output
})
