import { createReducer } from '@reduxjs/toolkit'

import {
  forgotPassword,
  getFavoriteList,
  loadUserData,
  login,
  logOut,
  verfyOTP,
  checkSessionExpired,
  resendOTP,
  setPassword,
  resetPassword, removeFavorite,
} from '../actions/user'
import { storage } from '../../utils/token'

import {
  FETCH_STATUS_ERROR, FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS,
} from '../../utils/request/status'

const initialState = {
  isSendingOTP: false,
  isReSendingOTP: false,
  isVerifyingOTP: false,
  isSendingloginData: false,
  islogin: !!storage.get('jwtToken'),
  user: undefined,
  load_user_data: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  phone: '',
  isTokenExpire: false,
  error: undefined,
  favorite: {
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: undefined,
    },
  },
  favoriteList: {
    data: [],
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: undefined,
    },
  },
  forgotEmail: {
    error: undefined,
    status: FETCH_STATUS_IDLE,
  },
  isLinkTokenExpire: false,
  set_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const userReducers = createReducer(initialState, (builder) => {
  builder
    .addCase(login.pending, (state) => {
      state.islogin = false
      state.isSendingloginData = true
      state.error = undefined
    })
    .addCase(login.fulfilled, (state, action) => {
      state.isVerifyingOTP = action.payload.expired
      state.phone = action.payload.phone
      state.isSendingloginData = false
      state.isTokenExpire = false
      state.preToken = action.payload.expired ? action.payload.token : undefined
      state.islogin = !action.payload.expired
    })
    .addCase(login.rejected, (state, action) => {
      state.error = action.payload
      state.isSendingloginData = false
    })
    .addCase(logOut.fulfilled, (state) => {
      state.islogin = false
      state.isTokenExpire = false
      state.load_user_data.status = FETCH_STATUS_IDLE
      state.load_user_data.error = undefined
    })
    .addCase(verfyOTP.pending, (state) => {
      state.isSendingOTP = true
    })
    .addCase(verfyOTP.fulfilled, (state) => {
      state.islogin = true
      state.isVerifyingOTP = false
      state.isSendingOTP = false
      state.isTokenExpire = false
    })
    .addCase(verfyOTP.rejected, (state, action) => {
      state.islogin = false
      state.isVerifyingOTP = true
      state.otpError = action.payload
      state.isSendingOTP = false
    })
    .addCase(resendOTP.pending, (state) => {
      state.isReSendingOTP = true
    })
    .addCase(resendOTP.fulfilled, (state) => {
      state.isReSendingOTP = false
    })
    .addCase(resendOTP.rejected, (state) => {
      state.isReSendingOTP = false
    })
    .addCase(loadUserData.pending, (state) => {
      state.load_user_data.status = FETCH_STATUS_REQUEST
    })
    .addCase(loadUserData.fulfilled, (state, action) => {
      state.user = action.payload
      state.load_user_data.status = FETCH_STATUS_SUCCESS
    })
    .addCase(loadUserData.rejected, (state, action) => {
      state.load_user_data.status = FETCH_STATUS_ERROR
      state.load_user_data.error = action.payload
    })
    .addCase(getFavoriteList.pending, (state) => {
      state.favoriteList.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(getFavoriteList.fulfilled, (state, action) => {
      state.favoriteList.fetch_status.status = FETCH_STATUS_SUCCESS
      state.favoriteList.data = action.payload?.reports
    })
    .addCase(getFavoriteList.rejected, (state, action) => {
      state.favoriteList.fetch_status.status = FETCH_STATUS_ERROR
      state.favoriteList.fetch_status.error = action.payload?.error
    })
    .addCase(removeFavorite.pending, (state) => {
      state.favorite.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(removeFavorite.fulfilled, (state) => {
      state.favorite.fetch_status.status = FETCH_STATUS_SUCCESS
    })
    .addCase(removeFavorite.rejected, (state, action) => {
      state.favorite.fetch_status.status = FETCH_STATUS_ERROR
      state.favoriteList.fetch_status.error = action.payload?.error
    })
    .addCase(checkSessionExpired.fulfilled, (state, action) => {
      state.isTokenExpire = action.payload
    })
    .addCase(forgotPassword.pending, (state) => {
      state.forgotEmail.status = FETCH_STATUS_REQUEST
    })
    .addCase(forgotPassword.fulfilled, (state) => {
      state.forgotEmail.error = undefined
      state.forgotEmail.status = FETCH_STATUS_SUCCESS
    })
    .addCase(forgotPassword.rejected, (state, action) => {
      state.forgotEmail.error = action.payload
      state.forgotEmail.status = FETCH_STATUS_ERROR
    })
    .addCase(setPassword.pending, (state) => {
      state.set_password_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(setPassword.fulfilled, (state) => {
      state.set_password_status.status = FETCH_STATUS_SUCCESS
      state.isLinkTokenExpire = false
    })
    .addCase(setPassword.rejected, (state, action) => {
      state.set_password_status.status = FETCH_STATUS_ERROR
      state.set_password_status.error = action.payload
      state.isLinkTokenExpire = true
    })
    .addCase(resetPassword.pending, (state) => {
      state.set_password_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(resetPassword.fulfilled, (state) => {
      state.set_password_status.status = FETCH_STATUS_SUCCESS
      state.isLinkTokenExpire = false
    })
    .addCase(resetPassword.rejected, (state, action) => {
      state.set_password_status.status = FETCH_STATUS_ERROR
      state.set_password_status.error = action.payload
      state.isLinkTokenExpire = true
    })
})

export const userState = (state) => state.User
export const favoriteList = (state) => state.User.favoriteList
export const favorite = (state) => state.User.favorite

export default userReducers
