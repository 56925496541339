import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../utils/request'
import { LAST_VIEW_REPORT_URL, MOST_VIEW_REPORT_URL } from './api-constraints'

import { checkSessionExpired } from './user'

export const getMostViewReport = createAsyncThunk('getMostViewReport', async (data, { dispatch, rejectWithValue }) => {
  let mostViewReportList
  try {
    mostViewReportList = await get({
      url: MOST_VIEW_REPORT_URL,
    })
  } catch (err) {
    dispatch(checkSessionExpired(err.response))
    throw rejectWithValue({ error: err.response })
  }
  return mostViewReportList
})

export const getLastViewReport = createAsyncThunk('getLastViewReport', async (data, { dispatch, rejectWithValue }) => {
  let lastViewReportList
  try {
    lastViewReportList = await get({
      url: LAST_VIEW_REPORT_URL,
    })
  } catch (err) {
    dispatch(checkSessionExpired(err.response))
    throw rejectWithValue({ error: err.response })
  }
  return lastViewReportList
})
