import React from 'react'
import ReactDOM from 'react-dom'
import './assets/styles/global-style.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import RouterPage from './route/Router'
import store from './store/store'
import * as serviceWorker from './serviceWorker'
import themeColor from './assets/styles/colors'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeColor}>
      <Provider store={store}>
        <Router>
          <RouterPage />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
