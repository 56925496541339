import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginPage from '../containers/LoginPage/loadable'
import ListReportPage from '../containers/ListReportPage/loadable'
import ReportPage from '../containers/ReportPage/loadable'
import HomePage from '../containers/HomePage/loadable'
import FavoritePage from '../containers/FavoritePage/loadable'
import ResetPasswordPage from '../containers/ResetPasswordPage/loadable'
import ApplicationLayout from '../containers/ApplicationLayout/loadable'
import SetPasswordPage from '../containers/SetPasswordPage/loadable'
import PageNotFound from '../containers/PageNotFound/loadable'
import ErrorPage from '../containers/ErrorPage/loadable'
import {
  FAVORITE_PAGE_PATH,
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  REPORT_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  SET_PASSWORD_PAGE_PATH,
  ERROR_PAGE_PATH,
  VIEW_REPORT_PAGE_PATH,
} from './routes'

function RouterPage() {
  const getContent = () => (
    <Switch>
      <Route path={LOGIN_PAGE_PATH} component={LoginPage}/>
      <Route path={RESET_PASSWORD_PAGE_PATH} component={ResetPasswordPage}/>
      <Route path={SET_PASSWORD_PAGE_PATH} component={SetPasswordPage}/>
      <ApplicationLayout>
        <Switch>
          <Route path={VIEW_REPORT_PAGE_PATH} component={ReportPage}/>
          <Route exact path={REPORT_PAGE_PATH} component={ListReportPage}/>
          <Route path={FAVORITE_PAGE_PATH} component={FavoritePage}/>
          <Route exact path={HOME_PAGE_PATH} component={HomePage}/>
          <Route path={ERROR_PAGE_PATH} component={ErrorPage} />
          <Route component={PageNotFound} />
        </Switch>
      </ApplicationLayout>
    </Switch>
  )

  return (
    <div className="App" id="App">
      {getContent()}
    </div>
  )
}

export default RouterPage
