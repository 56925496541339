import axios from 'axios'
import { storage } from '../token'

const jsonHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data
  }
  const error = new Error(response.statusText)
  error.response = response.data
  error.status = response.status
  throw error
}

export function call({
  url, method, body, headers,
}) {
  return (
    axios(url, {
      method,
      credentials: 'include',
      headers,
      // signal,
      data: body,
    }).then(checkHttpStatus)
  )
}

const createJsonHeader = (properties) => {
  const token = storage.get('jwtToken')
  return { ...jsonHeader, ...properties, Authorization: `Bearer ${token}` }
}

export function get({ url, headers, signal }) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({
    url, method: 'GET', headers: updatedJsonHeader, signal,
  })
}

export function post({
  url, body, headers, signal,
}) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({
    url, method: 'POST', headers: updatedJsonHeader, body, signal,
  })
}

export function put({
  url, body, headers, signal,
}) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({
    url, method: 'PUT', headers: updatedJsonHeader, body, signal,
  })
}

export function del({
  url, body, headers, signal,
}) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({
    url, method: 'DELETE', headers: updatedJsonHeader, body, signal,
  })
}

export function patch({
  url, body, headers, signal,
}) {
  const updatedJsonHeader = createJsonHeader(headers)
  return call({
    url, method: 'PATCH', headers: updatedJsonHeader, body, signal,
  })
}
