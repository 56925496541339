import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState = {
  somethingWentWrong: false,
  errorCode: undefined,
}

export const setUnHandleError = createAction('add/setUnHandleError')

const userReducers = createReducer(initialState, (builder) => {
  builder
    .addCase(setUnHandleError, (state, action) => {
      state.somethingWentWrong = action.payload.contain
      state.errorCode = action.payload.statusCode
    })
})

export const appState = (state) => state.app

export default userReducers
