const themeColor = {
  primaryDark: '#005C43',
  primaryLight: '#219653',
  secondary: '#FB8634',
  whitePrimary: '#FFFFFF',
  whiteSecondary: '#F1F7FF',
  whiteTertiary: '#F2F4F7',
  greyPrimary: '#D6DEE5',
  greySecondary: '#B3BAC0',
  greyTertiary: '#5E6164',
  greyQuaternary: '#3D4248',
  greyQuinary: '#5e6164',
  greyWithOpacityOneFour: '#e5e5e54f',
  successful: '#70C12E',
  error: '#D6423E',
}

export default themeColor
