import { combineReducers, configureStore } from '@reduxjs/toolkit'
import loginReducer from './reducers/userReducer'
import reportReducer from './reducers/reportReducer'
import trackReducer from './reducers/trackReducer'
import tokenCheckerReducer from './reducers/tokenCheckerReducer'
import AppReducer from './reducers/appReducer'

const combinedReducer = combineReducers({
  app: AppReducer,
  User: loginReducer,
  report: reportReducer,
  track: trackReducer,
  tokenChecker: tokenCheckerReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'login/fulfilled') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: process.env.NODE_ENV === 'development',
})
