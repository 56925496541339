import { createReducer } from '@reduxjs/toolkit'
import { getLastViewReport, getMostViewReport } from '../actions/track'
import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
} from '../../utils/request/status'

const initialState = {
  mostViewReport: {
    data: [],
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: '',
    },
  },
  lastViewReport: {
    data: [],
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: '',
    },
  },
}

const trackReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getMostViewReport.pending, (state) => {
      state.mostViewReport.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(getMostViewReport.fulfilled, (state, action) => {
      state.mostViewReport.data = action.payload
      state.mostViewReport.fetch_status.status = FETCH_STATUS_SUCCESS
    })
    .addCase(getMostViewReport.rejected, (state, action) => {
      state.mostViewReport.fetch_status.error = action.payload?.error
      state.mostViewReport.fetch_status.status = FETCH_STATUS_ERROR
    })
    .addCase(getLastViewReport.pending, (state) => {
      state.lastViewReport.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(getLastViewReport.fulfilled, (state, action) => {
      state.lastViewReport.data = action.payload
      state.lastViewReport.fetch_status.status = FETCH_STATUS_SUCCESS
    })
    .addCase(getLastViewReport.rejected, (state, action) => {
      state.lastViewReport.fetch_status.error = action.payload?.error
      state.lastViewReport.fetch_status.status = FETCH_STATUS_ERROR
    })
})

export const mostViewReportState = (state) => state.track.mostViewReport
export const lastViewReportState = (state) => state.track.lastViewReport

export default trackReducer
