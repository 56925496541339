import { createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../utils/request'
import { CHECK_TOKEN_EXPIRE_URL } from './api-constraints'

export const checkTokenExpire = createAsyncThunk('checkTokenExpire', async (token, { rejectWithValue }) => {
  let output
  const data = { token }
  try {
    output = await post({
      url: CHECK_TOKEN_EXPIRE_URL,
      body: data,
    })
  } catch (err) {
    return rejectWithValue(err.response.data.error.message)
  }
  return output
})
