import { createReducer } from '@reduxjs/toolkit'
import { getReportInfo, getReportList } from '../actions/report'
import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
} from '../../utils/request/status'

const initialState = {
  getReportData: {
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: undefined,
    },
    report: undefined,
    ticket: undefined,
  },
  getReportList: {
    fetch_status: {
      status: FETCH_STATUS_IDLE,
      error: undefined,
    },
    reportList: [],
  },
}

const reportReducers = createReducer(initialState, (builder) => {
  builder
    .addCase(getReportList.pending, (state) => {
      state.getReportList.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(getReportList.fulfilled, (state, action) => {
      state.getReportList.reportList = action.payload
      state.getReportList.fetch_status.status = FETCH_STATUS_SUCCESS
    })
    .addCase(getReportList.rejected, (state, action) => {
      state.getReportList.fetch_status.status = FETCH_STATUS_ERROR
      state.getReportList.fetch_status.error = action.payload?.error
    })
    .addCase(getReportInfo.pending, (state) => {
      state.getReportData.fetch_status.status = FETCH_STATUS_REQUEST
    })
    .addCase(getReportInfo.fulfilled, (state, action) => {
      state.getReportData.report = action.payload.report
      state.getReportData.ticket = action.payload.ticket?.ticket
      state.getReportData.fetch_status.status = FETCH_STATUS_SUCCESS
    })
    .addCase(getReportInfo.rejected, (state, action) => {
      state.getReportData.fetch_status.status = FETCH_STATUS_ERROR
      state.getReportData.fetch_status.error = action.payload?.error
    })
})

export const listReportState = (state) => state.report.getReportList
export const reportState = (state) => state.report.getReportData

export default reportReducers
