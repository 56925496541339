const END_POINT = `${process.env.REACT_APP_BACK_END_URL}`

// USER
export const LOGIN_URL = `${END_POINT}/user/login`
export const LOGOUT_URL = `${END_POINT}/user/logout`
export const OTP_URL = `${END_POINT}/user/verifyOtp`
export const RESEND_OTP_URL = `${END_POINT}/user/resendOtp`
export const USER_DATA_URL = `${END_POINT}/user/user`
export const FORGOT_PASSWORD_URL = `${END_POINT}/user/forgotPassword`
export const CHANGE_PASSWORD_LOGIN_URL = `${END_POINT}/user/login/changePassword`

// REPORT
export const TABLEAU_TOKEN_URL = `${END_POINT}/user/tableau/ticket`
export const TABLEAU_REPORT_URL = `${END_POINT}/store/report`
export const TABLEAU_REPORT_LIST_URL = `${END_POINT}/store/reports`

// TRACK
export const TRACK_REPORT_URL = `${END_POINT}/track/report`
export const MOST_VIEW_REPORT_URL = `${END_POINT}/track/mostViewReports`
export const LAST_VIEW_REPORT_URL = `${END_POINT}/track/lastViewReports`

// SET PASSWORD
export const SET_PASSWORD_URL = `${END_POINT}/user/setPassword`

// RESET PASSWORD
export const RESET_PASSWORD_URL = `${END_POINT}/user/changePassword`

// CHECK TOKEN EXPIRE
export const CHECK_TOKEN_EXPIRE_URL = `${END_POINT}/user/checkTokenExpired`

// FAVORITE
export const FAVORITE_URL = `${END_POINT}/store/favorite`
