import { createReducer } from '@reduxjs/toolkit'

import { checkTokenExpire } from '../actions/tokenChecker'
import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
} from '../../utils/request/status'

const initialState = {
  isTokenExpire: false,
  fetch_status: FETCH_STATUS_IDLE,
}

const tokenCheckerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(checkTokenExpire.pending, (state) => {
      state.fetch_status = FETCH_STATUS_REQUEST
    })
    .addCase(checkTokenExpire.fulfilled, (state, action) => {
      state.fetch_status = FETCH_STATUS_SUCCESS
      if (action.payload) {
        state.isTokenExpire = false
      } else {
        state.isTokenExpire = true
      }
    })
    .addCase(checkTokenExpire.rejected, (state) => {
      state.fetch_status = FETCH_STATUS_ERROR
      state.isTokenExpire = true
    })
})

export const isTokenExpire = (state) => state.tokenChecker.isTokenExpire
export const tokenCheckerStatus = (state) => state.tokenChecker.fetch_status

export default tokenCheckerReducer
